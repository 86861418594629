
@import "./normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,600;1,300&display=swap');
@import "styles/variables.scss";

html {
  scroll-behavior: smooth;
  font-size: calc(12px + 0.20vw);
  font-family: 'Raleway', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  background-color: white;
}

a {
  color: black;
  text-decoration: none;
}

.topBarElement {
  line-height: 50px;
  text-align: center;
  width: 180px;
  display: inline-block;
}
.menuElement {
  line-height: 70px;
  text-align: center;
  width: 90px;
  display: inline-block;
}


.pageContent {
  padding: 20px
}

.button {
  display: inline-block;
  padding: 5px;
  border: 2px solid $dark-blue;
}
.buttonInverted {
  color: white;
  border: 2px solid white;
  display: inline-block;
  padding: 5px;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #1dafce; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

li {
  margin-bottom: 20px;
}

p {
  line-height: 25px;
}

.partialUnderline {
  position: relative;
}
.partialUnderline:before {
  content: "";
  width: 70%;
  position: absolute;
  height: 1px;
  bottom: -20px;
  left: 15%;
  border-bottom: 1px solid #ccc;
}

.partialUnderlineWhite {
  position: relative;
}
.partialUnderlineWhite:before {
  content: "";
  width: 70%;
  position: absolute;
  height: 1px;
  bottom: -20px;
  left: 15%;
  border-bottom: 1px solid white;
}

.partialUnderlineBlack {
  position: relative;
}
.partialUnderlineBlack:before {
  content: "";
  width: 70%;
  position: absolute;
  height: 1px;
  bottom: -20px;
  left: 15%;
  border-bottom: 1px solid black;
}
