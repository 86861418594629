.notfaelleGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .textItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    text-align: center;
    .header {
      font-size: 48px;
    }

  }
  .imageItem {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .notfaelle {
    grid-column: 1;
    grid-row: 1;
  }
  .angstpatienten {
    grid-column: 2;
    grid-row: 1;
  }
  .image1 {
    grid-column: 3;
    grid-row: 1;
    background-image: url(/assets/pages/home/notfaelle/stuhl2.jpg);
  }
  .image2 {
    grid-column: 1/3;
    grid-row: 2;
    background-image: url(/assets/pages/home/notfaelle/gebiss.jpg);
  }
  .technologien {
    grid-column: 3;
    grid-row: 2;
    background-color: #1dafce;
  }

}