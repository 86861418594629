@import "../../styles/variables";

.leistungenBar {
  height: 450px;
  background-color: $dark-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  padding: 50px 20px;

  .leistungenElements {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-grow: 1;

    .leistungenElement {
      display: flex;
      align-items: center;
      flex-direction: column;
      border: 1px solid white;
      width: 250px;
      height: 200px;
      padding-top: 50px;
      margin: 30px;
      .header {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: white;
        line-height: 30px;
      }
      .subheader {
        color: white;
        font-style: italic;
      }
      .icon {
        height: 69px;
        margin-bottom: 30px;
      }
    }
  }

}
